const ReminderConstants = {
  STATUS: {
    NEW: 'NEW',
    ACK: 'ACK'
  },
  STATUS_OPTION: [
    { value: 'ACK', label: 'ACK' },
    { value: 'NEW', label: 'NEW' }
  ]
};

export default ReminderConstants;
