import antdZhHK from 'antd/es/locale/zh_HK';
import hkMsg from '../locales/zh_HK.json';

const HkLang = {
  antd: antdZhHK,
  locale: 'zh-HK',
  messages: {
    ...hkMsg
  }
};
export default HkLang;
