import authFetch from 'auth/AuthFetchInterceptor';

const Service = {};

Service.getAll = async function () {
  try {
    const resp = await authFetch({
      url: '/payment-methods',
      method: 'get',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (resp && resp.data) {
      return resp.data;
    }
  } catch (error) {}
  return null;
};

export default Service;
