import { useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { AUTH_TOKEN } from '../redux/constants/Auth';

export default function useCurrentUser() {
  const token = localStorage.getItem(AUTH_TOKEN);
  const decoded = jwt_decode(token);
  // return useSelector((state) => state.user.users.get(parseInt(decoded.sub)));
  return useSelector(
    (state) => state.user.userList.filter((item) => item.userId === parseInt(decoded.sub))[0]
  );
}

export function useUserNewReminder() {
  return useSelector((state) => state.user.userNewReminder);
}
