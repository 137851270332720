import {
  IdcardOutlined,
  CalendarOutlined,
  CommentOutlined,
  HomeOutlined,
  MedicineBoxOutlined,
  ProfileOutlined,
  TeamOutlined,
  DollarOutlined,
  BellOutlined
} from '@ant-design/icons';
import { AiOutlineMedicineBox } from 'react-icons/ai';
import { GiMedicines, GiMedicinePills } from 'react-icons/gi';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import permissionsConstants from 'constants/Permissions';

const homeNavTree = [
  {
    key: 'home',
    path: `${APP_PREFIX_PATH}/home`,
    title: 'topnav.home',
    icon: HomeOutlined,
    breadcrumb: false,
    submenu: []
  }
];

const patientNavTree = [
  {
    key: 'patient',
    path: `${APP_PREFIX_PATH}/patient`,
    title: 'topnav.patient',
    icon: IdcardOutlined,
    breadcrumb: false,
    permissionCode: permissionsConstants.PAT_MGMT.VIEW_PAT,
    submenu: []
  }
];

const bookingNavTree = [
  {
    key: 'booking',
    path: `${APP_PREFIX_PATH}/booking`,
    title: 'topnav.booking',
    icon: CalendarOutlined,
    breadcrumb: false,
    permissionCode: permissionsConstants.BK_MGMT.VIEW_BK_CAL,
    submenu: []
  }
];

const bookingPersNavTree = [
  {
    key: 'booking',
    path: `${APP_PREFIX_PATH}/booking`,
    title: 'topnav.booking',
    icon: CalendarOutlined,
    breadcrumb: false,
    permissionCode: permissionsConstants.BK_MGMT.VIEW_PERS_BK_CAL,
    submenu: []
  }
];

const consultationNavTree = [
  {
    key: 'consultation',
    path: `${APP_PREFIX_PATH}/consultation`,
    title: 'topnav.consultation',
    icon: CommentOutlined,
    breadcrumb: false,
    permissionCode: permissionsConstants.CONSULT_MGMT.VIEW_CONSULT_W_HIST,
    submenu: []
  }
];

const billingNavTree = [
  {
    key: 'billing',
    path: `${APP_PREFIX_PATH}/billing`,
    title: 'topnav.billing',
    icon: DollarOutlined,
    breadcrumb: false,
    permissionCode: permissionsConstants.BILL_MGMT.VIEW_BILL,
    submenu: []
  }
];

// const clinicNavTree = [{
//   key: 'clinic',
//   path: `${APP_PREFIX_PATH}/clinic`,
//   title: 'topnav.clinic',
//   icon: HomeOutlined,
//   breadcrumb: false,
//   submenu: []
// }]

const stockNavTree = [
  {
    key: 'stock',
    // path: `${APP_PREFIX_PATH}/stock/consumable`,
    title: 'topnav.stock',
    icon: MedicineBoxOutlined,
    breadcrumb: false,
    permissionCodesArr: [
      permissionsConstants.CONS_MGMT.VIEW_CONS,
      permissionsConstants.DRUG_MGMT.VIEW_DRUG,
      permissionsConstants.DRUGSET_MGMT.VIEW_DRUGSET
    ],
    submenu: [
      {
        key: 'consumable',
        path: `${APP_PREFIX_PATH}/stock/consumable`,
        title: 'topnav.stock.consumable',
        titleStyle: { marginLeft: '8px' },
        icon: AiOutlineMedicineBox,
        breadcrumb: false,
        permissionCode: permissionsConstants.CONS_MGMT.VIEW_CONS,
        submenu: []
      },
      {
        key: 'drug',
        path: `${APP_PREFIX_PATH}/stock/drug`,
        title: 'topnav.stock.drug',
        titleStyle: { marginLeft: '8px' },
        icon: GiMedicines,
        breadcrumb: false,
        permissionCode: permissionsConstants.DRUG_MGMT.VIEW_DRUG,
        submenu: []
      },
      {
        key: 'drugset',
        path: `${APP_PREFIX_PATH}/stock/drugset`,
        title: 'topnav.stock.drugset',
        titleStyle: { marginLeft: '8px' },
        icon: GiMedicinePills,
        breadcrumb: false,
        permissionCode: permissionsConstants.DRUGSET_MGMT.VIEW_DRUGSET,
        submenu: []
      }
    ]
  }
];

const reportNavTree = [
  {
    key: 'report',
    path: `${APP_PREFIX_PATH}/report`,
    title: 'topnav.report',
    icon: ProfileOutlined,
    breadcrumb: false,
    permissionCode: permissionsConstants.RPT.VIEW_RPT,
    submenu: []
  }
];

const reminderNavTree = [
  {
    key: 'reminder',
    path: `${APP_PREFIX_PATH}/reminder`,
    title: 'topnav.reminder',
    icon: BellOutlined,
    breadcrumb: false,
    permissionCode: permissionsConstants.RMDR_MGMT.VIEW_RMDR,
    submenu: []
  }
];

const userNavTree = [
  {
    key: 'user',
    path: `${APP_PREFIX_PATH}/user`,
    title: 'topnav.user',
    icon: TeamOutlined,
    breadcrumb: false,
    permissionCode: permissionsConstants.USR_MGMT.VIEW_USR,
    submenu: []
  }
];

const navigationConfig = [
  ...homeNavTree,
  ...patientNavTree,
  ...bookingNavTree,
  ...bookingPersNavTree,
  ...consultationNavTree,
  ...billingNavTree,
  // ...clinicNavTree,
  ...stockNavTree,
  ...reportNavTree,
  ...reminderNavTree,
  ...userNavTree
];

export default navigationConfig;
