import authFetch from 'auth/AuthFetchInterceptor';

const Service = {};

Service.getAll = async function (params) {
  try {
    const resp = await authFetch({
      url: '/resources',
      method: 'get',
      headers: {
        'Content-Type': 'application/json'
      },
      //   data: { grantType: "login", ...data },
      params
    });
    if (resp && resp.data) {
      return resp.data;
    }
  } catch (error) {}
  return null;
};

Service.getAllPages = async function (params) {
  let _allPagesData = [];
  try {
    let _currentPage = 0;
    let _totalPage = 1;
    while (_currentPage < _totalPage) {
      const _pageData = await this.getAll({
        pageSize: 20,
        page: _currentPage,
        ...params
      });
      if (_pageData) {
        if (_pageData.totalPage) {
          _totalPage = _pageData.totalPage;
        }
        if (_pageData.contents && _pageData.contents.length > 0) {
          _allPagesData = _allPagesData.concat(_pageData.contents);
        }
      }
      _currentPage += 1;
    }
  } catch (error) {}
  return _allPagesData;
};

export default Service;
