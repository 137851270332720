import authFetch from 'auth/AuthFetchInterceptor';
import { isEmpty } from 'lodash';

const Service = {};

Service.getAll = async function (params) {
  try {
    const resp = await authFetch({
      url: '/reminders',
      method: 'get',
      headers: {
        'Content-Type': 'application/json'
      },
      //   data: { grantType: "login", ...data },
      params
    });
    if (resp && resp.data) {
      return resp.data;
    }
  } catch (error) {}
  return null;
};

Service.create = async function (data) {
  try {
    const resp = await authFetch({
      url: '/reminders',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    });
    if (resp && resp.data) {
      return resp.data;
    }
  } catch (error) {}
  return null;
};

Service.getOne = async function (reminderId) {
  try {
    const resp = await authFetch({
      url: `/reminders/${reminderId}`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (resp && resp.data) {
      return resp.data;
    }
  } catch (error) {}
  return null;
};

Service.put = async function (reminderId, data) {
  try {
    const resp = await authFetch({
      url: `/reminders/${reminderId}`,
      method: 'put',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    });
    if (resp && resp.data) {
      return resp.data;
    }
  } catch (error) {}
  return null;
};

Service.delete = async function (reminderId) {
  try {
    const resp = await authFetch({
      url: `/reminders/${reminderId}`,
      method: 'delete',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return true;
  } catch (error) {}
  return null;
};

export default Service;
