import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Menu, Grid, Badge } from 'antd';
import IntlMessage from '../util-components/IntlMessage';
import Icon from '../util-components/Icon';
import navigationConfig from 'configs/NavigationConfig';
import rightNavigationConfig from 'configs/RightNavigationConfig';
import { connect } from 'react-redux';
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from 'constants/ThemeConstant';
import utils from 'utils';
import { onMobileNavToggle } from 'redux/actions/Theme';
import NavNotification from './NavNotification';
import { NavLanguage } from './NavLanguage';
import { NavProfile } from './NavProfile';
import { signOut } from 'redux/actions/Auth';
import useCurrentUser from '../../hooks/useCurrentUser';
import usePermissionsCheck from '../../hooks/usePermissionsCheck';

const _ = require('lodash/core');

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

// const setDefaultOpen = (key) => {
//   let keyList = [];
//   let keyString = "";
//   if (key) {
//     const arr = key.split("-");
//     for (let index = 0; index < arr.length; index++) {
//       const elm = arr[index];
//       index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
//       keyList.push(keyString);
//     }
//   }
//   return keyList;
// };

// const SideNavContent = (props) => {
// 	const { sideNavTheme, routeInfo, hideGroupTitle, localization, onMobileNavToggle } = props;
// 	const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
// 	const closeMobileNav = () => {
// 		if (isMobile) {
// 			onMobileNavToggle(false)
// 		}
// 	}
//   return (
//     <Menu
//       theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
//       mode="inline"
//       style={{ height: "100%", borderRight: 0 }}
//       defaultSelectedKeys={[routeInfo?.key]}
//       defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
//       className={hideGroupTitle ? "hide-group-title" : ""}
//     >
//       {navigationConfig.map((menu) =>
//         menu.submenu.length > 0 ? (
//           <Menu.ItemGroup
//             key={menu.key}
//             title={setLocale(localization, menu.title)}
//           >
//             {menu.submenu.map((subMenuFirst) =>
//               subMenuFirst.submenu.length > 0 ? (
//                 <SubMenu
//                   icon={
//                     subMenuFirst.icon ? (
//                       <Icon type={subMenuFirst?.icon} />
//                     ) : null
//                   }
//                   key={subMenuFirst.key}
//                   title={setLocale(localization, subMenuFirst.title)}
//                 >
//                   {subMenuFirst.submenu.map((subMenuSecond) => (
//                     <Menu.Item key={subMenuSecond.key}>
//                       {subMenuSecond.icon ? (
//                         <Icon type={subMenuSecond?.icon} />
//                       ) : null}
//                       <span>
//                         {setLocale(localization, subMenuSecond.title)}
//                       </span>
//                       <Link onClick={() => closeMobileNav()} to={subMenuSecond.path} />
//                     </Menu.Item>
//                   ))}
//                 </SubMenu>
//               ) : (
//                 <Menu.Item key={subMenuFirst.key}>
//                   {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
//                   <span>{setLocale(localization, subMenuFirst.title)}</span>
//                   <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
//                 </Menu.Item>
//               )
//             )}
//           </Menu.ItemGroup>
//         ) : (
//           <Menu.Item key={menu.key}>
//             {menu.icon ? <Icon type={menu?.icon} /> : null}
//             <span>{setLocale(localization, menu?.title)}</span>
//             {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path} /> : null}
//           </Menu.Item>
//         )
//       )}
//     </Menu>
//   );
// };

const TopNavContent = (props) => {
  const { topNavColor, localization, signOut, userPermissionsList, userNewReminder } = props;
  // const { topNavColor, localization, signOut, userCd } = props; //TODO: add userCd to show on Top Right Corner
  const history = useHistory();
  const currentUser = useCurrentUser();
  // console.log("useCurrentUser():" + useCurrentUser());
  // const currentUser = {userId: "abc"};
  // console.log('TopNavContent currentUser: ', currentUser);
  const checkMenuPermission = (permissionCode) => {
    return userPermissionsList.includes(permissionCode);
  };
  const checkMenuPermissionsArr = (permissionCodesArr) => {
    for (let index = 0; index < permissionCodesArr.length; index++) {
      const element = permissionCodesArr[index];
      if (userPermissionsList.includes(element)) {
        return true;
      }
    }
    return false;
  };
  const displayMenu = (menuItem, isSecond = false) => {
    if (
      !menuItem.permissionCode ||
      (menuItem.permissionCode && checkMenuPermission(menuItem.permissionCode))
    ) {
      return (
        <Menu.Item key={menuItem.key}>
          {!isSecond && menuItem.icon ? <Icon type={menuItem?.icon} /> : null}
          <span style={menuItem?.titleStyle}>{setLocale(localization, menuItem?.title)}</span>
          {menuItem.path ? <Link to={menuItem.path} /> : null}
        </Menu.Item>
      );
    }
  };

  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          !menu.permissionCodesArr ||
          (menu.permissionCodesArr && checkMenuPermissionsArr(menu.permissionCodesArr)) ? (
            <SubMenu
              key={menu.key}
              popupClassName="top-nav-menu"
              onTitleClick={() => {
                if (menu.path) {
                  history.push(menu.path);
                }
              }}
              title={
                <span>
                  {menu.icon ? <Icon type={menu?.icon} /> : null}
                  <span style={menu?.titleStyle}>{setLocale(localization, menu.title)}</span>
                </span>
              }>
              {menu.submenu.map((subMenuFirst) =>
                subMenuFirst.submenu.length > 0 ? (
                  <SubMenu
                    key={subMenuFirst.key}
                    popupClassName="top-nav-menu"
                    icon={
                      subMenuFirst.icon ? (
                        <Icon type={subMenuFirst?.icon} style={{ marginRight: '20px' }} />
                      ) : null
                    }
                    title={setLocale(localization, subMenuFirst.title)}
                    style={subMenuFirst?.titleStyle}>
                    {subMenuFirst.submenu.map((subMenuSecond) => displayMenu(subMenuSecond, true))}
                  </SubMenu>
                ) : (
                  displayMenu(subMenuFirst)
                )
              )}
            </SubMenu>
          ) : null
        ) : (
          displayMenu(menu)
        )
      )}

      {rightNavigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            style={{ marginLeft: 'auto' }}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                {menu.title === 'topnav.profile' ? (
                  _.isEmpty(currentUser) ? (
                    ''
                  ) : (
                    currentUser.userCode
                  )
                ) : (
                  <span>{setLocale(localization, menu.title)}</span>
                )}
                {menu.title === 'topnav.profile' ? (
                  <Badge
                    style={{ transform: 'translate(0%, -15%)' }}
                    count={userNewReminder}></Badge>
                ) : null}
              </span>
            }>
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                !subMenuFirst.permissionCode ||
                (subMenuFirst.permissionCode &&
                  checkMenuPermission(subMenuFirst.permissionCode)) ? (
                  <SubMenu
                    key={subMenuFirst.key}
                    popupClassName="top-nav-menu"
                    icon={subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                    title={setLocale(localization, subMenuFirst.title)}>
                    {subMenuFirst.submenu.map((subMenuSecond) => (
                      <Menu.Item key={subMenuSecond.key}>
                        <span>{setLocale(localization, subMenuSecond.title)}</span>
                        <Link to={subMenuSecond.path} />
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : null
              ) : !subMenuFirst.permissionCode ||
                (subMenuFirst.permissionCode &&
                  checkMenuPermission(subMenuFirst.permissionCode)) ? (
                <Menu.Item
                  key={subMenuFirst.key}
                  onClick={(e) => {
                    if (subMenuFirst.key === 'logout') {
                      signOut();
                    }
                  }}>
                  {subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  {subMenuFirst.title === 'topnav.myreminder' ? (
                    <Badge
                      style={{ transform: 'translate(30%, -15%)' }}
                      count={userNewReminder}></Badge>
                  ) : null}
                  {/*<Link to={subMenuFirst.path} />*/}
                  {_.isEmpty(subMenuFirst.path) ? null : <Link to={subMenuFirst.path} />}
                </Menu.Item>
              ) : null
            )}
          </SubMenu>
        ) : !menu.permissionCode ||
          (menu.permissionCode && checkMenuPermission(menu.permissionCode)) ? (
          <Menu.Item key={menu.key} style={{ marginLeft: 'auto' }}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span style={{ marginLeft: '20px' }}>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        ) : null
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  // return props.type === NAV_TYPE_SIDE ? (
  //   <SideNavContent {...props} />
  // ) : (
  //   <TopNavContent {...props} />
  // );
  return <TopNavContent {...props} />;
};

const mapStateToProps = ({ theme, auth, user }) => {
  const { sideNavTheme, topNavColor } = theme;
  const { userPermissionsList, userNewReminder } = user;
  // const { userCd } = user; TODO: change to user
  return { sideNavTheme, topNavColor, userPermissionsList, userNewReminder };
  // return { sideNavTheme, topNavColor, userCd }; TODO: change to user
};

export default connect(mapStateToProps, { onMobileNavToggle, signOut })(MenuContent);
