import { SET_PAYMENT_METHOD_LIST } from '../constants/PaymentMethod';

const initState = {
  paymentMethodList: []
};

const paymentMethod = (state = initState, action) => {
  switch (action.type) {
    case SET_PAYMENT_METHOD_LIST:
      return {
        ...state,
        paymentMethodList: action.paymentMethodList
      };
    default:
      return state;
  }
};

export default paymentMethod;
