import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import permissionsConstants from '../../constants/Permissions';
import usePermissionsCheck, { usePermissionCheckArr } from '../../hooks/usePermissionsCheck';

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />

        {usePermissionsCheck(permissionsConstants.PAT_MGMT.VIEW_PAT) ? (
          <Route path={`${APP_PREFIX_PATH}/patient`} component={lazy(() => import(`./patient`))} />
        ) : null}

        {usePermissionCheckArr([
          permissionsConstants.BK_MGMT.VIEW_BK_CAL,
          permissionsConstants.BK_MGMT.VIEW_PERS_BK_CAL
        ]) ? (
          <Route path={`${APP_PREFIX_PATH}/booking`} component={lazy(() => import(`./booking`))} />
        ) : null}

        {/*<Route path={`${APP_PREFIX_PATH}/clinic`} component={lazy(() => import(`./clinic`))} />*/}

        {usePermissionsCheck(permissionsConstants.CONSULT_MGMT.VIEW_CONSULT_W_HIST) ? (
          <Route
            path={`${APP_PREFIX_PATH}/consultation`}
            component={lazy(() => import(`./consultation`))}
          />
        ) : null}

        {usePermissionsCheck(permissionsConstants.BILL_MGMT.VIEW_BILL) ? (
          <Route path={`${APP_PREFIX_PATH}/billing`} component={lazy(() => import(`./billing`))} />
        ) : null}

        {usePermissionsCheck(permissionsConstants.RPT.VIEW_RPT) ? (
          <Route path={`${APP_PREFIX_PATH}/report`} component={lazy(() => import(`./report`))} />
        ) : null}

        {usePermissionsCheck(permissionsConstants.RMDR_MGMT.VIEW_RMDR) ? (
          <Route
            path={`${APP_PREFIX_PATH}/reminder`}
            component={lazy(() => import(`./reminder`))}
          />
        ) : null}

        {usePermissionsCheck(permissionsConstants.USR_MGMT.VIEW_USR) ? (
          <Route path={`${APP_PREFIX_PATH}/user`} component={lazy(() => import(`./user`))} />
        ) : null}

        {usePermissionCheckArr([
          permissionsConstants.DRUG_MGMT.VIEW_DRUG,
          permissionsConstants.DRUGSET_MGMT.VIEW_DRUGSET,
          permissionsConstants.CONS_MGMT.VIEW_CONS
        ]) ? (
          <Route path={`${APP_PREFIX_PATH}/stock`} component={lazy(() => import(`./stock`))} />
        ) : null}

        <Route
          path={`${APP_PREFIX_PATH}/personal/change-password`}
          component={lazy(() => import(`./personal/change-password`))}
        />

        {usePermissionsCheck(permissionsConstants.RMDR_MGMT.VIEW_PERS_RMDR) ? (
          <Route
            path={`${APP_PREFIX_PATH}/personal/my-reminder`}
            component={lazy(() => import(`./personal/my-reminder`))}
          />
        ) : null}

        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
