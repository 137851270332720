import {
  SET_USER_LIST,
  SET_USER_PERMISSIONS_LIST,
  SET_USER_NEW_REMINDER
  // SET_USER_MAP,
} from '../constants/User';

const initState = {
  userList: [],
  userPermissionsList: [],
  userNewReminder: 0
  // users: new Map(),
};

const user = (state = initState, action) => {
  switch (action.type) {
    case SET_USER_LIST:
      return {
        ...state,
        userList: action.userList
      };
    case SET_USER_PERMISSIONS_LIST:
      return {
        ...state,
        userPermissionsList: action.userPermissionsList
      };
    case SET_USER_NEW_REMINDER:
      return {
        ...state,
        userNewReminder: action.userNewReminder
      };
    default:
      return state;
  }
};
// const user = (state = initState, action) => {
//     switch (action.type) {
//         case SET_USER_MAP:
//             return {
//                 ...state,
//                 users: action.users,
//             };
//         default:
//             return state;
//     }
// };

export default user;
