import fetch from 'auth/FetchInterceptor';
import { AUTH_TOKEN, REFRESH_TOKEN } from 'redux/constants/Auth';
import jwt from 'jsonwebtoken';
import { API_ENDPOINT } from '../constants/ApiConstant';
import { USER_CD } from '../redux/constants/User';

const JwtAuthService = {};

JwtAuthService.login = async function (data) {
  try {
    const resp = await fetch({
      url: API_ENDPOINT.TOKEN_AUTH,
      // url: '/signer/test_api',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: { grantType: 'login', ...data }
    });
    if (resp && resp.data && resp.data.accessToken && resp.data.refreshToken) {
      localStorage.setItem(AUTH_TOKEN, resp.data.accessToken);
      localStorage.setItem(REFRESH_TOKEN, resp.data.refreshToken);
      // TODO: getUserByCode here
      // localStorage.setItem(USER_CD, data.login);
      // console.log("localStorage.getItem(USER_CD)", localStorage.getItem(USER_CD));
      return resp.data.accessToken;
    }
  } catch (error) {}
  return null;
};

JwtAuthService.refreshToken = async function (data) {
  const _refreshToken = localStorage.getItem(REFRESH_TOKEN);
  const resp = await fetch({
    url: API_ENDPOINT.TOKEN_AUTH,
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: { grantType: 'refreshToken', token: _refreshToken }
  });

  if (resp && resp.data && resp.data.accessToken) {
    localStorage.setItem(AUTH_TOKEN, resp.data.accessToken);
    localStorage.setItem(REFRESH_TOKEN, resp.data.refreshToken);
    return true;
  }
  return false;
};

JwtAuthService.checkTokenExpire = function (data) {
  try {
    const jwtToken = localStorage.getItem(AUTH_TOKEN);
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    if (jwtToken && refreshToken) {
      const decodedJwt = jwt.decode(jwtToken);
      if (decodedJwt && decodedJwt.exp) {
        const ts = Date.now();
        // console.log("checkTokenExpire ts:", ts);
        // console.log("checkTokenExpire decodedJwt.exp:", decodedJwt.exp);
        if (decodedJwt.exp * 1000 > ts + 300) {
          return true;
        }
      }
    }
  } catch (error) {}
  return false;
};

JwtAuthService.signUp = function (data) {
  return fetch({
    url: '/auth/signup',
    method: 'post',
    headers: {
      'public-request': 'true'
    },
    data: data
  });
};

export default JwtAuthService;
