import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import history from '../history';
import { API_ENDPOINT } from '../constants/ApiConstant';
import { AUTH_TOKEN, REFRESH_TOKEN } from 'redux/constants/Auth';
import { notification } from 'antd';
// import jwt from "jsonwebtoken";

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000
});

// // Config
const ENTRY_ROUTE = '/auth/login';
// const TOKEN_PAYLOAD_KEY = "authorization";
// const PUBLIC_REQUEST_KEY = "public-request";

// API Request interceptor
// service.interceptors.request.use(
//   (config) => {
//     //   if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
//     // 		history.push(ENTRY_ROUTE)
//     // 		window.location.reload();
//     //   }

//     return config;
//   },
//   (error) => {
//     console.log("requestInterceptors error:", error);
//     // Do something with request error here
//     notification.error({
//       message: "Error",
//     });
//     Promise.reject(error);
//   }
// );

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    console.log('111 Fetch responseInterceptors response:', response);
    return response.data;
  },
  (error) => {
    // console.log("Fetch responseInterceptors error:", error);
    console.log('Fetch responseInterceptors error:', error);
    let _errorMsg = '';
    if (error?.response?.data?.errors[0]?.message) {
      _errorMsg = error?.response?.data?.errors[0]?.message;
    }
    const errorJson = error.toJSON();
    console.log('Fetch responseInterceptors errorJson:', errorJson);

    let notificationParam = {
      message: 'System Error!'
    };

    // Remove token and redirect
    if (errorJson) {
      if (errorJson.status === 401 || errorJson.status === 403) {
        notificationParam.message = 'Authentication Fail';
      } else if (errorJson.status === 404) {
        notificationParam.message = 'Not Found';
      } else if (errorJson.status === 500) {
        if (!_errorMsg) {
          notificationParam.message = _errorMsg;
        }
      } else if (errorJson.status === 508) {
        notificationParam.message = 'Time Out';
      }

      if (
        errorJson?.config?.url == API_ENDPOINT.TOKEN_AUTH &&
        errorJson?.config?.data?.includes('refreshToken')
      ) {
        notificationParam.message = 'Authentication Fail';
        notificationParam.description = 'Please login again';
        notification.error(notificationParam);
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem(REFRESH_TOKEN);
        history.push(ENTRY_ROUTE);
        window.location.reload();
      }
    }

    notification.error(notificationParam);

    return Promise.reject(error);
  }
);

export default service;
