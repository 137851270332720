import { UserOutlined, GlobalOutlined, LogoutOutlined, BellOutlined } from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import permissionsConstants from 'constants/Permissions';

const profileNavTree = [
  {
    key: 'profile',
    path: `${APP_PREFIX_PATH}/profile`,
    title: 'topnav.profile',
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'change_password',
        path: `${APP_PREFIX_PATH}/personal/change-password`,
        title: 'topnav.changepassword',
        icon: BellOutlined,
        breadcrumb: true,
        submenu: []
      },
      {
        key: 'my_reminder',
        // path: `${AUTH_PREFIX_PATH}/login-1`,
        path: `${APP_PREFIX_PATH}/personal/my-reminder`,
        title: 'topnav.myreminder',
        icon: BellOutlined,
        breadcrumb: true,
        submenu: [],
        permissionCode: permissionsConstants.RMDR_MGMT.VIEW_PERS_RMDR
      },
      {
        key: 'logout',
        // path: `${AUTH_PREFIX_PATH}/login-1`,
        path: ``,
        title: 'topnav.logout',
        icon: LogoutOutlined,
        breadcrumb: true,
        submenu: []
      }
    ]
  }
];

const langNavTree = [
  {
    key: 'lang',
    path: `${APP_PREFIX_PATH}/lang`,
    title: 'topnav.lang',
    icon: GlobalOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'en',
        // path: `${AUTH_PREFIX_PATH}/login-1`,
        title: 'topnav.english',
        icon: '',
        breadcrumb: true,
        submenu: []
      }
    ]
  }
];

const rightNavigationConfig = [...profileNavTree];

export default rightNavigationConfig;
