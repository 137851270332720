import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import history from '../history';
import { AUTH_TOKEN, REFRESH_TOKEN } from 'redux/constants/Auth';
import { notification } from 'antd';
import JwtAuthService from '../services/JwtAuthService';

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000
});

// Config
const ENTRY_ROUTE = '/auth/login';
const TOKEN_PAYLOAD_KEY = 'authorization';
const PUBLIC_REQUEST_KEY = 'public-request';

// API Request interceptor
service.interceptors.request.use(
  async (config) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN);
    if (jwtToken) {
      const checkTokenExpire = JwtAuthService.checkTokenExpire();
      if (checkTokenExpire) {
        config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
        return config;
      } else {
        const refreshTokenResult = await JwtAuthService.refreshToken();
        if (refreshTokenResult) {
          const jwtToken = localStorage.getItem(AUTH_TOKEN);
          config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
          return config;
        }
      }
    }

    //   if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
    // 		history.push(ENTRY_ROUTE)
    // 		window.location.reload();
    //   }
    // throw "No Access Token";
    let notificationParam = {
      message: 'Authentication Fail',
      description: 'Please login again'
    };
    notification.error(notificationParam);
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    history.push(ENTRY_ROUTE);
    window.location.reload();
  },
  (error) => {
    console.log('requestInterceptors error:', error);
    // Do something with request error here
    notification.error({
      message: 'Error'
    });
    Promise.reject(error);
  }
);

// API response interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // console.log("AuthFetch responseInterceptors error:", error);
    console.log('Fetch responseInterceptors error:', error);
    let _errorMsg = '';
    if (error?.response?.data?.errors[0]?.message) {
      _errorMsg = error?.response?.data?.errors[0]?.message;
    }
    console.log('AuthFetch responseInterceptors _errorMsg:', _errorMsg);
    const errorJson = error.toJSON();
    console.log('AuthFetch responseInterceptors errorJson:', errorJson);

    let notificationParam = {
      message: 'System Error!'
    };

    // Remove token and redirect
    if (errorJson) {
      console.log('AuthFetch responseInterceptors errorJson.status:', errorJson.status);
      if (errorJson.status === 401 || errorJson.status === 403) {
        notificationParam.message = 'Authentication Fail';
        // notificationParam.description = "Please login again";
        // notification.error(notificationParam);
        // localStorage.removeItem(AUTH_TOKEN)
        // localStorage.removeItem(REFRESH_TOKEN)
        // history.push(ENTRY_ROUTE)
        // window.location.reload();
      } else if (errorJson.status === 404) {
        notificationParam.message = 'Not Found';
      } else if (errorJson.status === 400) {
        if (_errorMsg) {
          notificationParam.message = _errorMsg;
        }
      } else if (errorJson.status === 500) {
        if (!_errorMsg) {
          notificationParam.message = _errorMsg;
        }
      } else if (errorJson.status === 508) {
        notificationParam.message = 'Time Out';
      }
    }

    console.log('AuthFetch responseInterceptors notificationParam:', notificationParam);
    notification.error(notificationParam);

    return Promise.reject(error);
  }
);

export default service;
