import { SIDE_NAV_LIGHT, NAV_TYPE_TOP, DIR_LTR } from 'constants/ThemeConstant';
// import { env } from './EnvironmentConfig'

export const APP_NAME = 'Clinic Top';
export const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT_URL;
export const APP_VERSION = `v${process.env.REACT_APP_NPM_VERSION}`;
// export const APP_VERSION = "v1.1.8";
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: 'en',
  navType: NAV_TYPE_TOP,
  topNavColor: '#FFFFFF',
  headerNavColor: '',
  mobileNav: false,
  currentTheme: 'light',
  direction: DIR_LTR
};
