import { useSelector } from 'react-redux';

export default function usePermissionCheck(permissionCode) {
  return useSelector((state) => state?.user?.userPermissionsList?.includes(permissionCode));
}

export function usePermissionCheckArr(permissionCodesArr) {
  return useSelector((state) => {
    for (let index = 0; index < permissionCodesArr.length; index++) {
      const element = permissionCodesArr[index];
      if (state?.user?.userPermissionsList?.includes(element)) {
        return true;
      }
    }
    return false;
  });
}

export function usePermissionModuleCheck(moduleCode) {
  return useSelector((state) =>
    state?.user?.userPermissionsList?.some((item) => item.includes(`${moduleCode}.`))
  );
}
